//#region Settings
//#region SettingsOther
export const Categories = {
  getAll: "/api/Categories/GetAllCategories",
  getDetails: "/api/Categories/GetCategoryDetails",
  getDialog: "/api/Categories/GetCategoriesDialog",
  add: "/api/Categories/AddCategory",
  update: "/api/Categories/UpdateCategory",
  changeActivationType: "/api/Categories/ChangeActivationTypeCategory",
  finalDelete: "/api/Categories/DeleteCategory",
  deleteImage: "/api/Categories/DeleteImage",
};

export const Products = {
  getAll: "/api/Products/GetAllProducts",
  getDetails: "/api/Products/GetProductDetails",
  getDialog: "/api/Products/GetProductDialog",
  add: "/api/Products/AddProduct",
  update: "/api/Products/UpdateProduct",
  changeActivationType: "/api/Products/ChangeActivationTypeProduct",
  finalDelete: "/api/Products/DeleteProduct",
  deleteImage: "/api/Products/DeleteImage",
};

export const EducationalStages = {
  getAll: "/api/EducationalStages/GetAllEducationalStages",
  getDetails: "/api/EducationalStages/GetEducationalStageDetails",
  getDialog: "/api/EducationalStages/GetEducationalStageDialog",
  add: "/api/EducationalStages/AddEducationalStage",
  update: "/api/EducationalStages/UpdateEducationalStage",
  changeActivationType:
    "/api/EducationalStages/ChangeActivationTypeEducationalStage",
  finalDelete: "/api/EducationalStages/DeleteEducationalStage",
  deleteImage: "/api/EducationalStages/DeleteImage",
};

export const UserEducationalStages = {
  getAll: "/api/UserEducationalStages/GetAllUserEducationalStages",
};

//#endregion SettingsOther

export const EstablishmentRole = {
  getAll: "/api/EstablishmentRoles/GetAllEstablishmentRoles",
  getDetails: "/api/EstablishmentRoles/GetEstablishmentRoleDetails",
  getDialog: "/api/EstablishmentRoles/GetEstablishmentRoleDialog",
  add: "/api/EstablishmentRoles/AddEstablishmentRole",
  update: "/api/EstablishmentRoles/UpdateEstablishmentRole",
  changeActivationType:
    "/api/EstablishmentRoles/ChangeActivationTypeEstablishmentRole",
  finalDelete: "/api/EstablishmentRoles/DeleteEstablishmentRole",
  deleteImage: "/api/EstablishmentRoles/DeleteImage",
};

export const UserLoginCodes = {
  getAll: "/api/UserLoginCodes/GetAllUserLoginCodes",
  getDetails: "/api/UserLoginCodes/GetUserLoginCodeDetails",
  getDialog: "/api/UserLoginCodes/GetUserLoginCodeDialog",
  getSuggestion: "/api/UserLoginCodes/SuggestionNewUserLoginCode",
  add: "/api/UserLoginCodes/AddUserLoginCode",
  update: "/api/UserLoginCodes/UpdateUserLoginCode",
  resetUserLoginCode: "/api/UserLoginCodes/ResetUserLoginCode",
  resetUserDeviceCode: "/api/UserLoginCodes/ResetUserDeviceCode",
  useLoginCode: "/api/UserLoginCodes/UseLoginCode",
  changeActivationType: "/api/UserLoginCodes/ChangeActivationTypeUserLoginCode",
  finalDelete: "/api/UserLoginCodes/DeleteUserLoginCode",
  deleteImage: "/api/UserLoginCodes/DeleteImage",
};

//#endregion Settings

//#region UserAppSettings
export const UserAppSettings = {
  get: "/api/UserAppSettings/GetUserAppSettingDetails",
  update: "/api/UserAppSettings/UpdateUserAppSetting",
};

//#endregion UserAppSettings

//#region Notifications
export const Notifications = {
  getAll: "/api/Notifications/GetAllNotifications",
  getDetails: "/api/Notifications/GetNotificationDetails",
  getCountNotRead: "/api/Notifications/GetCountNotReadNotfcations",
  read: "/api/Notifications/ReadNotification",
  readAll: "/api/Notifications/ReadAllNotifications",
  openAll: "/api/Notifications/OpenAllNotification",
};
//#endregion Notifications

//#region Users
export const Users = {
  getAll: "/api/Users/GetAllUsers",
  getDetails: "/api/Users/GetUserDetails",
  getDialog: "/api/Users/GetUserDialog",
  getStudentsIdReport: "/api/Users/ExportStudentsId",
  getStudentsWalletReport: "/api/Users/GetStudentsWalletReport",
  add: "/api/Users/AddUser",
  update: "/api/Users/UpdateUser",
  changeActivationType: "/api/Users/ChangeActivationTypeUser",
  finalDelete: "/api/Users/DeleteUser",
  deleteImage: "/api/Users/DeleteImage",
};
//#endregion Users
//#region StudentParents
export const StudentParents = {
  add: "/api/UserStudentParents/AddAndUpdateStudentWithParentsJson",
  update: "/api/UserStudentParents/AddAndUpdateStudentWithParentsJson",
};
//#endregion StudentParents

//#region UserWalletTransactions
export const UserWalletTransactions = {
  getAll: "/api/UserWalletTransactions/GetAllUserWalletTransactions",
  getDetails: "/api/UserWalletTransactions/GetUserWalletTransactionDetails",
  getUserWalletTransactionsReport:
    "/api/UserWalletTransactions/GetAllUserWalletTransactionsReport",
  getUserWalletTransactionReceiptReport:
    "/api/UserWalletTransactions/ExtractUserWalletTransactionReceiptReport",
  add: "/api/UserWalletTransactions/AddUserWalletTransaction",
};
//#endregion UserWalletTransactions

//#region UserOrders
export const UserOrders = {
  getReport: "/api/UserOrders/GetAllUserOrdersReport",
};
//#endregion UserOrders
